@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');

body {

  .electrolize-regular {
    font-family: "Electrolize", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  margin: 0;

}

